@import url("https://fonts.googleapis.com/css2?family=Inter&family=Poppins&family=Cormorant&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  scroll-behavior: smooth;
}

body {
  width: 100%;
  position: relative;
  min-height: 100vh;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f4f3f3;
}

#icon {
  width: 20px; 
  height: 20px; 
}

#mobile {
  width: 28px; 
  height: 28px; 
}

#privacy {
  list-style: square; 
}

.sidebar {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
